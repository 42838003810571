import axios from 'axios'

const BASE_URL = 'https://hyerhub.ghost.io/ghost/api/v3/content'
const apiKey = '459ea3a1432754e4ff8ed73a99'
const apiInclude = '&include=authors,tags'
const apiFilter = '&filter=visibility:public'

export const getBlogPosts = async (limit, tag) => {
  const tagFilter = `%2Btag:${tag}`;
  const apiLimit = `&limit=${limit}`
  return (await axios.get(`${BASE_URL}/posts/?key=${apiKey}${apiInclude}${apiFilter}${tagFilter}${apiLimit}`)).data
}
