import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="slider"
export default class extends Controller {
  static targets = ["items", "prev", "next"]

  connect() {
    this.observer = new IntersectionObserver(
      this.observedChanges.bind(this),
      {
        root: this.itemsTarget,
        threshold: 0
      }
    );

    for (let item of Array.from(this.itemsTarget.querySelectorAll('.card'))) {
      this.observer.observe(item);
    }

    if (this.hasPrevTarget) {
      this.prevTarget.addEventListener('click', e => {
        if (e.target.classList.contains('inactive')) { return; }
        return this.prev();
      });
    }

    if (this.hasNextTarget) {
      this.nextTarget.addEventListener('click', e => {
        if (e.target.classList.contains('inactive')) { return; }
        return this.next();
      });
    }
  }

  observedChanges(entries, observer) {
    for (let entry of Array.from(entries)) {
      const parentElem = entry.target.parentElement;
      if (!entry.isIntersecting) {
        if ((entry.boundingClientRect.x - entry.rootBounds.x) <= 0) {
          parentElem.dataset.position = 'prev';
        } else {
          parentElem.dataset.position = 'next';
        }
      } else {
        delete parentElem.dataset.position;
      }
    }

    if (this.element.querySelector('[data-position="prev"]')) {
      this.prevTarget.classList.remove('inactive');
    } else {
      this.prevTarget.classList.add('inactive');
    }

    if (this.element.querySelector('[data-position="next"]')) {
      this.nextTarget.classList.remove('inactive');
    } else {
      this.nextTarget.classList.add('inactive');
    }
  }

  prev() {
    const prev = Array.from(this.element.querySelectorAll('[data-position="prev"] .card')).pop();
    if (prev) {
      prev.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }

  next() {
    const next = this.element.querySelector('[data-position="next"] .card');
    if (next) {
      return next.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
    }
  }
}
