import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-modal"
export default class extends Controller {
  static targets = ['modalTitle']
  static values = { class: String }

  connect() {
    this.element.classList.add('open')
    const body = this.element.closest('body')
    body.classList.add('with-modal')
    if (this.hasModalTitleTarget) {
      this.element.querySelector('#turbo_modal_title').innerHTML = this.modalTitleTarget.innerHTML
      this.modalTitleTarget.remove()
    }
    if (this.classValue) {
      this.element.classList.add(this.classValue)
    }
  }
  disconnect() {
    const body = document.querySelector('body')
    body.classList.remove('with-modal')
  }

  hideModal(e) {
    if (e.cancelable) {
      e.preventDefault()
    }
    this.element.parentElement.removeAttribute("src")
    this.element.remove()
  }
}
