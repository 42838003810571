import { Controller } from "@hotwired/stimulus"

// Cloudflare will not cache pages that set cookies, but a session is needed to set the CSRF token
// This code sets the CSRF token from the session via JS after the page has loaded, so that we
// can skip the cookie session

// Connects to data-controller="session"

export default class extends Controller {
  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').content;
    fetch('/session-cookie', {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": this.token
      },
      credentials: 'same-origin'
    }).then (response => response.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}
