import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="interact-loader"
export default class extends Controller {
  static targets = ['pending']

  connect(){
    document.addEventListener('scroll', (e) => {
      // TODO: should use scrollend event but it is not supported by safari yet (09-2024)
      this.loadPendingTargets()
    })
    document.addEventListener('click', (e) => {
      this.loadPendingTargets()
    })
  }

  loadPendingTargets(){
    this.pendingTargets.forEach((target) => {
      if('src' in target.dataset && target.src === ''){
        target.src = target.dataset.src
        target.removeAttribute('data-src')
        target.removeAttribute('data-interact-loader-target')
      }
    })
  }
}
