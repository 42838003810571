<template>
  <div class="tags">
    <ul>
      <li v-for="tag in tags" :key="tag.id" :ref="setTagItems">
        <a :href="tag.url">
          {{ tag.name }}
        </a>
      </li>
    </ul>
    <span>{{ hiddenItems }}</span>
  </div>
</template>

<script>
export default {
  name: "PostTags",
  props: {
    tags: Array
  },
  data() {
    return {
      tagItems: [],
      hiddenItems: "",
    }
  },
  methods: {
    setTagItems(tagItem) {
      if (tagItem && !this.tagItems.includes(tagItem)) {
        this.tagItems.push(tagItem)
      }
    },
    getHiddenTags(tagItems) {
      const tagsYPositions = tagItems.map(x => x.getBoundingClientRect().y);
      const minY = Math.min(...tagsYPositions);

      return tagsYPositions.filter(x => x > minY).length;
    }
  },
  mounted() {
    setInterval(() => {
      const hiddenCount = this.getHiddenTags(this.tagItems);
      this.hiddenItems = hiddenCount > 0 ? `+${hiddenCount}` : '';
      this.count++;
    }, 100)
  }
}
</script>

<style lang="scss" scoped>
@import "@/stylesheets/application/base/config";

.tags {
  display: flex;
  justify-content: center;
  order: 1;

  ul {
      margin: 0;
      padding: 0;
      list-style: none;
      flex-wrap: wrap;
      overflow: hidden;
      white-space: nowrap;
      height: 2.4rem;

      display: flex;
      justify-content: center;

      li {
        z-index: 20;

        &:first-of-type {
          margin-left: 8px;
        }

        margin: 0 8px 3px 0;
        line-height: 1.4em;

        a {
          color: $prussian-blue;
          border: 1px solid $jungle-mist-grey;
          color: $william-teal;
          border-radius: 3px;
          padding: 8px 10px;
          font-size: 0.7rem;
          white-space: nowrap;
          transition: all .2s;
          text-decoration: none;

          &:hover {
            border: 1px solid $regal-blue;
            background: $regal-blue;
            color: #fff;
          }
        }
      }
  }

  span {
    display: block;
    margin-left: 5px;
    font-size: 0.7rem;
    color: $jungle-mist-grey;
    line-height: 2.4rem;
  }
}
</style>
