<template>
  <div class="post-card">
    <a class="blog-link" :href="link" :aria-label="title"></a>
    <img class="feature-img" alt="" :src="featureImg" loading="lazy" />
    <div class="content">
      <div class="post-date">{{ getPostDate() }}</div>
      <PostTags :tags="tags" />
      <header>
          <h2>{{ title }}</h2>
      </header>
      <div class="excerpt">
          <p>{{ excerpt }}</p>
      </div>
      <div class="author">
        <a :href="author.url" class="author">
          <img class="blur-up" alt="" :src="author.profile_image" loading="lazy" />
          <span>{{ author.name }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import PostTags from './PostTags.vue';
import moment from 'moment';

export default {
  name: "BlogPost",
  components: { PostTags },
  props: {
    tags: Array,
    title: String,
    featureImg: String,
    date: String,
    link: String,
    excerpt: String,
    author: Object
  },
  data: function () {
    return {
      transparentImage: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
    }
  },
  methods: {
    getPostDate() {
      const date = moment(this.date);
      return date.format("DD MMM YYYY");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/stylesheets/application/base/config";

.post-card {
  box-flex: 1;
  flex: 1 0 0;

  min-width: 280px;

  position: relative;

  min-height: 300px;
  background: #fff 50%;
  background-size: cover;
  box-shadow: 8px 14px 8px rgba(39, 44, 49, 0.06),
              1px 3px 8px rgba(39, 44, 49, 0.03);
  transition: all .5s ease;

  display: flex;
  flex-direction: column;

  @container (width > 430px){
    min-width: 350px;
  }

  .blog-link {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10;
  }

  &:hover {
    transition: all .3s ease;
    transform: translate3D(0,-1px,0);
    box-shadow: 0 0 4px #51ff9c, 0 0 4px #51ff9c, 0 0 4px #51ff9c, 0 0 4px #51ff9c
  }

  .content {
    padding: 10px 10px 7px;
    box-flex: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $william-teal;
    font-size: 1.4rem;
    text-align: center;
    height: calc(100% - 116px);

    @container (width > 430px){
      height: calc(100% - 200px);
      padding: 10px 40px 7px;
    }

    > * {
      margin: 10px 0
    }

    a {
      color: $william-teal;
    }

    a:hover {
      text-decoration: underline;
    }

    header {
      flex: 2 0;
      order: 2;
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;

      @container (width > 430px){
        flex: 2 0 2.2rem;
      }

      h2 {
        text-align: center;
        margin: 0;

        @container (width > 430px){
          margin: 20px 0 10px;
        }
      }
    }

    .post-date {
      order: 5;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0,37,68,.51);
      padding: 2px 6px;
      margin: 0;
      color: #fff;
      font-size: 0.8rem;
    }

    .excerpt {
      order: 3;
      overflow: hidden;

      @container (width > 430px){
        flex: 4 0 7.2rem;
      }

      p {
        margin: 0 10%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 14px;
        color: #6e787c;

        @container (width > 430px){
          font-size: 1.0rem;
        }
      }
    }

    .author {
      order: 4;
      z-index: 20;
      width: fit-content;
      margin: 0 auto;

      @container (width > 430px){
        margin: 10px auto;
      }

      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin: 0 auto;
      }

      .blur-up {
        transition: opacity .2s,filter .3s,-webkit-filter .3s;
        object-fit: cover;
      }
      .blur-up[complete] {
        opacity: 1;
        filter: blur(0);
      }

      span {
        display: none;
        margin: 0 auto;
        font-size: 0.9rem;
        font-weight: bold;

        @container (width > 430px){
          display: block;
        }
      }
    }
  }

  .feature-img {
    width: 100%;
    height: 116px;
    background: $jungle-mist-grey no-repeat 50%;
    object-fit: cover;

    @container (width > 430px){
      height: 200px;
    }
  }
}
</style>
