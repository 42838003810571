import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
// To replace old toggle.js targets, search and replace the following:
// data-toggle= -> data-controller="toggle"
// data-toggle= -> data-toggle-target-value=
// data-toggle-within= -> data-toggle-within-value=
export default class extends Controller {
  static values = { target: String, within: String }

  connect(){
    if(this.withinValue && this.element.closest(this.withinValue)){
      this.targetElement = this.element.closest(this.withinValue).querySelector(this.targetValue)
    } else {
      this.targetElement = this.element.closest(this.targetValue)
    }
    if (this.targetElement){
      this.element.addEventListener('click', this.toggle.bind(this))
    }
  }

  toggle(event){
    // TODO: really the toggling should be done by the state
    // ariaExpanded attribute not a class name, this way the
    // accessibility and CSS remain in sync
    event.preventDefault()
    this.element.classList.toggle('open')
    this.targetElement.classList.toggle('open')
  }
}
