import { Controller } from "@hotwired/stimulus"

// This is necessary because we want Frame requests to be cached differently by Cloudflare
// We often (e.g. for modals) serve a "frame" version (without application layout) and a "full"
// version (with application layout, at the same route. We can differentiate based on the
// turbo-frame header, but Cloudflare can't and will treat based versions as the same. We add
// a new extension to frame based requests so that cloudflare see's them as separate resources
// this is only applied to get requests, as Cloudflare doesn't cache other requests

// Connects to data-controller="frame_request"

export default class extends Controller {
  fileExtension = '.frame'

  connect() {
    this.element.addEventListener('turbo:before-fetch-request', this.handleTurboBeforeFetchRequest.bind(this))
  }

  handleTurboBeforeFetchRequest(event) {
    let detail = event.detail
    if (!detail.fetchOptions.headers['Turbo-Frame'] || detail.fetchOptions.method != 'GET') {
      return
    }
    detail.url.href = this.ensureFrameExtension(detail.url.href)
  }

  ensureFrameExtension(url) {
    let urlObject = new URL(url)
    if (!urlObject.pathname.endsWith(this.fileExtension)) {
      urlObject.pathname = urlObject.pathname + this.fileExtension
    }
    return urlObject.toString()
  }
}
