import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="faq"
export default class extends Controller {
  static targets = ['question', 'answer']

  connect() {
    if(this.questionTarget.hash === window.location.hash) {
      this.toggle(new Event('connected'))
      this.element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }
  }

  questionTargetConnected(element) {
    element.addEventListener('click', this.toggle.bind(this))
  }

  toggle(event) {
    event.preventDefault()
    // TODO: really the toggling should be done by the state
    // ariaExpanded attribute not a class name, this way the
    // accessibility and CSS remain in sync
    this.element.classList.toggle('open')
    let visible = this.element.classList.contains('open')
    if (visible && event.type === 'click') {
      window.history.pushState(
        { href: this.questionTarget.href },
        this.questionTarget.innerText,
        this.questionTarget.href
      )
      this.element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }
    this.answerTarget.ariaExpanded = visible
  }
}
